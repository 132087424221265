$(document).ready(function(){
	$(window).load(function(){
		
			//alert();
		$('#prodlist .product-item').on('click', '.btn-view', function(){
			$('#thick-img').attr('src', $(this).data('image-thick'));
			$('#prod-mod-img').attr('src', $(this).data('img'));
			$('#prod-mod-title').html($(this).data('title'));
			$('#size-specs').html($(this).data('specs'));
			
			// $('#btn').html($(this).data('inquiry'));
		});

		$('#prodlist .product-item').on('click', '.btn-inquire', function(){
			// $('#prod-mod-title').html($(this).data('title'));
			$('#span-title').html($(this).data('title'));
			// $('#btn').html($(this).data('inquiry'));
		});

		// $('#prodlist .product-item').on('click', '.btn-btn', function(){
		// 	// $('#prod-mod-title').html($(this).data('title'));
		// 	$('#span-title').html($(this).data('title'));
		// 	// $('#btn').html($(this).data('inquiry'));
		// });


		$('#banner-slider').slick({
			prevArrow: false,
			nextArrow: false,
			dots: true,
			infinite: true,
			autoplay: false,
			speed: 2000,
			fade: false,
			slidesToShow: 1,
			adaptiveHeight: true
		});

		$('.project-listing').slick({
			 arrows: false,
		// 	prevArrow:"<img class='a-left control-c prev slick-prev' src='../images/prev.png'>",
  //     		nextArrow:"<img class='a-right control-c next slick-next' src='../images/ne.png'>"
		// nextArrow: '.next_03.png',
		// prevArrow: '.prev.png',
		  dots: false,
		  infinite: true,
		  speed: 2000,
		  slidesToShow: 4,
		  slidesToScroll: 4,
		  responsive: [
		    {
		      breakpoint: 1024,
		      settings: {
		        slidesToShow: 3,
		        slidesToScroll: 3,
		        infinite: true,
		        dots: false
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		    // You can unslick at a given breakpoint now by adding:
		    // settings: "unslick"
		    // instead of a settings object
		  ]
		});

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		$('.menu-container .menu-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});

		$(document).on('click', '.mobile-menu.notAnimated', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$(this).removeClass('notAnimated').addClass('animated');
			$('.menu-container').addClass('show');
			setTimeout(function(){
				$('.menu-container').addClass('animate');
			}, 500);
		});

		$(document).on('click', '.mobile-menu.animated', function(){
			$(this).removeClass('animated').addClass('notAnimated');

			$('.menu-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.menu-container').removeClass('show');
			}, 500);

			setTimeout(function(){
				$('.menu-container .menu-contain nav ul li .sub-menu').removeClass('show');
			}, 700);

		});

		$(document).on('click', '.submenu-button', function(){
			$(this).parent('li').find(' > .sub-menu').addClass('show');
		});
		$(document).on('click', '.submenu-close-button', function(){
			$(this).parent('li').parent('ul').removeClass('show');
		});

		$(document).on('click', 'a[href^="mailto:"].main', function(e){
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.mailto-container').addClass('show');
			setTimeout(function(){
				$('.mailto-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.mailto-close-button', function(){
			$('.mailto-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.mailto-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '.search-btn', function(){
			$('body, html').css({
				'overflow' : 'hidden'
			});
			$('.search-form-template').addClass('show');
			setTimeout(function(){
				$('.search-form-template').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.search-close-button', function(){
			$('body, html').removeAttr('style');
			$('.search-form-template').removeClass('show');
			setTimeout(function(){
				$('.search-form-template').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.testimonial-item-contain', function(){
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_testimonial',
					id : $(this).data('id')
				},
				beforeSend: function(){
					$('body, html').css({
						'overflow' : 'hidden'
					});
					$('.popup-modal-container').addClass('show');
					$('html').addClass('popupshow');
				},
				success: function(response) {
					
					setTimeout(function(){
						$('.popup-modal-container').addClass('animate');
					}, 300);
					$('.popup-modal-container .popup-body').html(response.content);
			
				}
			});
		});

		$(document).on('click', '.popup-close', function(){
			$('body, html').removeAttr('style');
			$('header.site-header.push-top').removeAttr('style');
			$('.popup-modal-container').removeClass('show');
			$('html').removeClass('popupshow');
			setTimeout(function(){
				$('.popup-modal-container').removeClass('animate');
			}, 300);
		});


		$(document).on('click', '.apply-btn', function(e){
			var position = $(this).data('position');
			e.preventDefault();
			$('body, html').css({
				'overflow' : 'hidden'
			});

			$('.career-container .yourPosition input').val(position);
			$('.career-container').addClass('show');
			setTimeout(function(){
				$('.career-container').addClass('animate');
			}, 300);
		});

		$(document).on('click', '.career-close-button', function(){
			$('.career-container').removeClass('animate');
			$('body, html').removeAttr('style');

			setTimeout(function(){
				$('.career-container').removeClass('show');
			}, 300);
		});

		$(document).on('click', '#resumeBtn', function(){
			var input = $('.resume-container .resume input');
			input.click();
		});

		$('.resume-container .resume input').change(function(e){
			var FileName = e.target.files[0];
			$('#resumeBtn').addClass('active').text(FileName.name);
		});

		$(document).on('click', '.expand-term', function(e){
			e.preventDefault();
			$(this).toggleClass('fa-angle-down').toggleClass('fa-angle-up');
			$(this).parent('a').parent('.parent-term').find('.chid-term').first().toggleClass('active');
		});

		$(document).on('click', '.expand-selection-term', function(e){
			$('.archive-sidebar .term-container').toggleClass('active');
		});

		if( $('.product-archive-template').length > 0 ) {
			$.ajax({
				url : $('.ajaxlocation').data('ajaxlocation'),
				type : 'POST',
				dataType: 'json',
				data : {
					action : 'request_products'
				},
				beforeSend: function(){},
				success: function(response) {
					setTimeout(function(){
						$(document).on('keyup', '#suggest-input', function(){
							$( "#suggest-input" ).autocomplete({
						    	source: response.titles
						    });
						});
					}, 300);
				}
			});
			$(document).on('keyup', '#suggest-input', function(){
				var val = $(this).val();
				var tax = $(this).data('tax');
				$.ajax({
					url : $('.ajaxlocation').data('ajaxlocation'),
					type : 'POST',
					dataType: 'json',
					data : {
						action : 'get_products',
						s : val,
						tax : tax
					},
					beforeSend: function(){
						$('.product-archive-template .product-lists').css({
							'opacity' : 0.2
						});
					},
					success: function(response) {
						$('.product-archive-template .product-lists').css({
							'opacity' : 1
						}).html(response.content);

						$('.product-lists .product-item').on('click', '.btn-inquire', function(){
							// $('#prod-mod-title').html($(this).data('title'));
							$('#span-title').html($(this).data('title'));
							// $('#btn').html($(this).data('inquiry'));
							
						});
					}
				});
			});
			$(document).on('submit', '#global-search', function(e){
				e.preventDefault();
				var val = $(this).val();
				var tax = $(this).data('tax');

				$.ajax({
					url : $('.ajaxlocation').data('ajaxlocation'),
					type : 'POST',
					dataType: 'json',
					data : {
						action : 'get_products',
						s : val,
						tax : tax
					},
					beforeSend: function(){
						$('.product-archive-template .product-lists').css({
							'opacity' : 0.2
						});
					},
					success: function(response) {
						$('.product-archive-template .product-lists').css({
							'opacity' : 1
						}).html(response.content);

						$('.product-lists .product-item').on('click', '.btn-inquire', function(){
							// $('#prod-mod-title').html($(this).data('title'));
							$('#span-title').html($(this).data('title'));
							// $('#btn').html($(this).data('inquiry'));
							
						});
					}
				});
				return false;
			});

			$(document).on('click', '#ui-id-1 li', function(e){
				var val = $('#suggest-input').val();
				console.log(val);
				var tax = $('#suggest-input').data('tax');
				$.ajax({
					url : $('.ajaxlocation').data('ajaxlocation'),
					type : 'POST',
					dataType: 'json',
					data : {
						action : 'get_products',
						s : val,
						tax : tax
					},
					beforeSend: function(){
						$('.product-archive-template .product-lists').css({
							'opacity' : 0.2
						});
					},
					success: function(response) {
						$('.product-archive-template .product-lists').css({
							'opacity' : 1
						}).html(response.content);

						$('.product-lists .product-item').on('click', '.btn-inquire', function(){
							// $('#prod-mod-title').html($(this).data('title'));
							$('#span-title').html($(this).data('title'));
							// $('#btn').html($(this).data('inquiry'));
							
						});
					}
				});
				return false;
			});


		}

	});
});